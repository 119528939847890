@import url('https://cdnjs.cloudflare.com/ajax/libs/animate.css/4.1.1/animate.min.css');

body {
  font-family: 'Poppins', Arial, sans-serif;
  font-size: 16px;
  line-height: 1.6;
  color: #333;
  margin: 0;
  padding: 0;
  position: relative;
  background: linear-gradient(to bottom, #e0f7ff, #e0ffe0); /* Light gradient background */
}

/* Home Hero Section */
.home-hero-section {
  position: relative;
  height: 60vh;
  background: url('../Assist/headerair.webp') no-repeat center center fixed;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  text-align: center;
}

.home-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
}

.home-hero-section .container {
  z-index: 1;
}

.home-hero-content {
  z-index: 2;
}

.home-hero-title {
  font-size: 3rem;
  margin-bottom: 10px;
}

.home-hero-subtitle {
  font-size: 1.5rem;
}

.home-hero-button {
  margin-top: 20px;
}

/* Animations */
@keyframes fadeInDown {
  from {
    opacity: 0;
    transform: translateY(-50px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(50px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Responsive styles for home-hero-section */
@media (max-width: 768px) {
  .home-hero-title {
    font-size: 2.5rem;
  }

  .home-hero-subtitle {
    font-size: 1.2rem;
  }
}

/* Product Section */
.product-section {
  padding: 20px 0;
  background: #f9f9f9;
}

.product-row {
  display: flex;
  align-items: center;
  padding: 30px 0;
}

.product-row-reverse {
  flex-direction: row-reverse;
}

.product-content {
  flex: 1;
  padding: 20px;
}

.product-image {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.product-image img {
  max-width: 100%;
  height: auto;
  border-radius: 10px;
  transition: transform 0.3s ease;
}

.product-content h2 {
  font-size: 2.5rem;
  margin-bottom: 20px;
  color: #333;
}

.product-content p {
  font-size: 1.2rem;
  color: #555;
}

/* Animations */
.product-image img:hover {
  transform: scale(1.05);
}

/* Responsive styles for product-section */
@media (max-width: 768px) {
  .product-row {
    flex-direction: column;
  }

  .product-row-reverse {
    flex-direction: column;
  }

  .product-content, .product-image {
    padding: 10px;
  }

  .product-content h2 {
    font-size: 2rem;
  }

  .product-content p {
    font-size: 1rem;
  }
}

/* Features Section */
.features-section {
  padding: 20px 0;
  background: #f9f9f9;
}

.features-section .container {
  max-width: 1200px;
  margin: 0 auto;
}

.features-section h3 {
  text-align: center;
  margin-bottom: 40px;
}

.features-section .row {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.features-section .card {
  margin: 15px;
  max-width: 350px;
  height: 200px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
  border: none;
}

.features-section .card:hover {
  transform: translateY(-10px);
}

.features-section .card-body {
  display: flex;
  padding: 20px;
}

.features-section .card-content {
  display: flex;
}

.features-section .card-img-left {
  height: 100px;
  width: 100px;
  object-fit: cover;
  margin-right: 15px;
  
}

.features-section .text-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.features-section .card-title {
  font-weight: bold;
  margin-bottom: 10px;
}

.features-section .card-text {
  margin: 0;
}

/* Responsive styles for features-section */
@media (max-width: 768px) {
  .features-section .card {
    max-width: 100%;
  }

  .features-section .card-body {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  .features-section .card-img-left {
    margin-right: 0;
    margin-bottom: 15px;
  }
}

/* Testimonials Section */
.testimonials-section {
  padding: 20px 0;
  background: #f9f9f9;
}

.testimonials-section .container {
  max-width: 1200px;
  margin: 0 auto;
}

.testimonials-section h2 {
  text-align: center;
  margin-bottom: 40px;
}

.testimonials-section .row {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.testimonials-section .card {
  margin: 15px;
  max-width: 350px;
 
  transition: transform 0.3s ease;
  border: none;
}

.testimonials-section .card:hover {
  transform: translateY(-10px);
}

.testimonials-section .card-img-top {
  width: 100%; /* Adjusted width */
  height: 100%; /* Adjusted height */
  object-fit: cover;
  float: left; /* Floated left */
  margin-right: 15px; /* Margin to create space between image and text */
  
}





.testimonials-section .card-body {
  padding: 20px;
}

.testimonials-section .card-title {
  margin-top: 10px;
  font-weight: bold;
}

/* Responsive styles for testimonials-section */
@media (max-width: 768px) {
  .testimonials-section .card {
    max-width: 100%;
    
  }

  .testimonials-section .card-body {
    text-align: center;
  }

  .testimonials-section .card-img-top {
    margin: 0 auto 15px; /* Center the image and add bottom margin */
    float: none; /* Remove the float */
  }
}

/* Contact Banner */
.contact-banner {
  position: fixed;
  top: 80vh;
  right: 5vh;
  z-index: 1000;
  overflow: hidden;
  cursor: pointer;
  transition: transform 0.3s ease;
}

.contact-banner:hover {
  transform: scale(1.1);
}

.contact-banner img {
  height: 100px;
  width: 150px;
}

.contact-banner span {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 24px;
  color: #ffffff;
  font-weight: bold;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}

/* Responsive styles for contact-banner */
@media (max-width: 768px) {
  .contact-banner {
    top: 70vh;
    right: 4vh;
  }
}
