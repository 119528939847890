/* Import animations */
@import url('https://cdnjs.cloudflare.com/ajax/libs/animate.css/4.1.1/animate.min.css');
@import url('https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.4/css/all.min.css');

/* Basic styling */
body {
  margin: 0;
  font-family: 'Montserrat', sans-serif;
  background: #f5f5f5;
}

.aboutus-hero-section {
  position: relative;
  height: 60vh;
  background: url('../Assist/aboutheader.jpg') no-repeat center center fixed;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  text-align: center;
}

.aboutus-hero-section .aboutus-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
}

.aboutus-hero-section .container {
  z-index: 1;
}

.aboutus-hero-title {
  font-size: 3rem;
  margin-bottom: 10px;
}

.aboutus-hero-subtitle {
  font-size: 1.5rem;
}

.content-section {
  padding: 50px 20px;
  background: #fff;
}

.content-row {
  display: flex;
  align-items: center;
  margin-bottom: 40px;
}

.content-row-reverse {
  flex-direction: row-reverse;
}

.content-image {
  flex: 1;
  padding: 20px;
}

.content-image img {
  width: 100%;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
}

.content-text {
  flex: 1;
  padding: 20px;
}

.content-text h2 {
  font-size: 2rem;
  margin-bottom: 10px;
}

.content-text p {
  font-size: 1rem;
  line-height: 1.6;
}

.team-section {
  padding: 50px 20px;
  background: #f9f9f9;
  text-align: center;
}

.team-title {
  font-size: 2.5rem;
  margin-bottom: 40px;
}

.team-grid {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}






.team-member {
  flex: 1 0 30%;
  max-width: 30%;
  margin: 20px;
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  text-align: center;
  padding: 20px;
  transition: transform 0.3s;
}

.team-member img {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  margin-bottom: 15px;
}

.team-member h3 {
  font-size: 1.5rem;
  margin-bottom: 5px;
}

.team-member p {
  font-size: 1rem;
  color: #666;
  margin-bottom: 15px;
}

.team-member .linkedin-link {
  color: #0077b5;
  font-size: 1.2rem;
  text-decoration: none;
  transition: color 0.3s;
}

.team-member .linkedin-link:hover {
  color: #005582;
}

.team-member:hover {
  transform: translateY(-10px);
}

.supported-by-section {
  padding: 50px 20px;
  background: #fff;
  text-align: center;
}

.supported-by-title {
  font-size: 2.5rem;
  margin-bottom: 40px;
}

.logos-grid {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.logo {
  max-width: 150px;
  margin: 20px;
  transition: transform 0.3s;
}

.logo:hover {
  transform: scale(1.1);
}

/* Responsive design */
@media (max-width: 768px) {
  .aboutus-hero-title {
    font-size: 2rem;
  }

  .aboutus-hero-subtitle {
    font-size: 1.2rem;
  }

  .content-row {
    flex-direction: column;
  }

  .content-row-reverse {
    flex-direction: column-reverse;
  }

  .team-member {
    flex: 1 0 80%;
    max-width: 80%;
  }

  .logo {
    max-width: 100px;
  }
}



.contact-banner {
  position: fixed;
  top: 80vh; 
  right: 5vh;
  z-index: 1000; 
  overflow: hidden;
  cursor: pointer;
  transition: transform 0.3s ease;
}

.contact-banner:hover {
  transform: scale(1.1);
}

.contact-banner img {
  
  height: 100px;
  width: 150px;
}

.contact-banner span {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 24px;
  color: #ffffff;
  font-weight: bold;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}


@media (max-width: 768px) {
  .contact-banner {
    top: 70vh;
    right: 5vh;
  }
}