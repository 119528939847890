/* navbar.css */

.navbar {
  background-color: #f8f9fa;
  padding: 15px 0;
  border-bottom: 2px solid #ddd;
  transition: all 0.3s ease;
}

.navbar-brand img {
  max-width: 100%;
  height: auto;
}

.navbar-toggler {
  border: none;
  background: transparent;
}

.navbar-toggler-icon {
  color: #333;
}

.navbar-nav .nav-link {
  font-size: 1.2rem;
  font-weight: 500;
  color: #333;
  padding: 10px 20px;
  transition: color 0.3s ease;
}

.navbar-nav .nav-link:hover {
  color: #8edbf0;
  
  border-radius: 5px;
}

.navbar-nav .nav-item:not(:last-child) {
  margin-right: 15px;
}

.navbar-nav .nav-link.active {
  color: #00b336 !important;
  
  
  border-radius: 5px;
}

@media (max-width: 991px) {
  .navbar-nav .nav-link {
    font-size: 1rem;
    padding: 8px 15px;
  }
}
