/* Import animations */
@import url('https://cdnjs.cloudflare.com/ajax/libs/animate.css/4.1.1/animate.min.css');

/* Basic styling */
body {
  margin: 0;
  
  background: #f5f5f5;
}

/* Contact page container */
.contact-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 50px 20px;
  background: url('../Assist/contback.jpg') no-repeat center center fixed;
  background-size: cover;
  color: #fff;
  text-align: center;
}

/* Contact header */
.contact-header {
  margin-bottom: 40px;
}

.contact-title {
  font-size: 3rem;
  margin-bottom: 10px;
}

.contact-subtitle {
  font-size: 1.5rem;
}

/* Contact form container */
.contact-form-container {
  background: rgba(0, 0, 0, 0.7);
  padding: 30px;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
  width: 100%;
  max-width: 600px;
}

/* Contact form styling */
.contact-form .form-group {
  margin-bottom: 10px;
}

.contact-form label {
  display: block;
  margin-bottom: 8px;
  font-size: 1rem;
  font-weight: bold;
}

.contact-form input,
.contact-form textarea,
.contact-form select {
  width: 100%;
  padding: 10px;
  border: none;
  border-radius: 5px;
  background: rgba(255, 255, 255, 0.8);
  font-size: 1rem;
}

.contact-form input:focus,
.contact-form textarea:focus,
.contact-form select:focus {
  outline: none;
  background: rgba(255, 255, 255, 1);
}

.contact-form textarea {
  resize: none;
}

.btn-submit {
  display: inline-block;
  padding: 12px 30px;
  background:#58b554;

  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1rem;
  transition: background 0.3s ease;
}

.btn-submit:hover {
  background: #ff4757;
}

/* Responsive design */
@media (max-width: 768px) {
  .contact-title {
    font-size: 2.5rem;
  }

  .contact-subtitle {
    font-size: 1.2rem;
  }

  .contact-form-container {
    padding: 20px;
  }

  .btn-submit {
    width: 100%;
    padding: 15px;
  }
}
