/* SwatchPawn Page Styles */
.swatchpawan-page {
  font-family: 'Arial', sans-serif;
  background-color: #f4f4f4;
}

/* Section Styles */
section {
  padding: 60px 20px;
  background-color: #fff;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
}

.theory-section, .unique-section {
  padding: 60px 0;
  text-align: center;
}

.theory-title, .unique-title {
  font-size: 2.5rem;
  margin-bottom: 20px;
  color: rgb(100, 217, 100);
  position: relative;
}

.theory-title::after, .unique-title::after {
  content: "";
  display: block;
  width: 50px;
  height: 4px;
  background-color: rgb(116, 184, 210);
  margin: 10px auto;
}

.theory-description, .unique-description {
  font-size: 1.2rem;
  color: #555;
}

/* Contact Banner */
.contact-banner {
  position: fixed;
  right: 5vh;
  z-index: 1000;
  overflow: hidden;
  cursor: pointer;
  transition: transform 0.3s ease;
}

.contact-banner:hover {
  transform: scale(1.1);
}

.contact-banner img {
  height: 100px;
  width: 150px;
}

.contact-banner span {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 24px;
  color: #ffffff;
  font-weight: bold;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}

/* Responsive styles for contact-banner */
@media (max-width: 768px) {
  .contact-banner {
    top: 70vh;
    right: 4vh;
  }
}

/* Responsive Styles */
@media (max-width: 768px) {
  .theory-title, .unique-title {
    font-size: 2rem;
  }

  .theory-description, .unique-description {
    font-size: 1rem;
  }
}

/* Carousel Styles */
.carousel-inner img {
  width: 100%;
  height: 90vh;
  object-fit: cover;
}

.carousel-item {
  position: relative;
}

.carousel-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 20px;
  box-sizing: border-box;
  color: #63cbeb;
  background: linear-gradient(90deg, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.1));
  clip-path: polygon(0 0, 95% 0, 100% 100%, 5% 100%); /* Initial clip-path for large screens */
  animation: slideDown 2s forwards, clipPathAnimation 2s forwards;
}

.carousel-overlay-content {
  position: relative;
  z-index: 2;
  opacity: 0;
  margin-top: 70px;
  text-align: left;
  max-width: 40%;
  padding: 15px;
  animation: contentFadeIn 2s forwards 2s;
}

@media (max-width: 768px) {
  .carousel-overlay-content {
    max-width: 80%;
    padding: 5px;
    font-size: 0.9rem;
    margin-top: 5px !important;
  }
}

@keyframes slideDown {
  0% {
    top: -100%;
  }
  100% {
    top: 0;
  }
}

@keyframes clipPathAnimation {
  0% {
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
  }
  100% {
    clip-path: polygon(0 0, 70% 0, 35% 100%, 0 100%); /* Adjusted clip-path for small screens */
  }
}

@keyframes contentFadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.carousel-caption h5 {
  font-size: 2rem;
  margin-bottom: 10px;
}

.carousel-caption p {
  font-size: 1.2rem;
  margin: 0;
}

/* Additional Media Queries for Smaller Devices */
@media (max-width: 576px) {
  .carousel-inner img {
    width: 100%;
    height: auto;
  }
}

@media (max-width: 480px) {
  .carousel-caption h5 {
    font-size: 1rem;
  }

  .carousel-caption p {
    font-size: 0.8rem;
  }

  .carousel-inner img {
    height: 30vh;
  }

  /* Adjust overlay for small screens */
  .carousel-overlay {
    clip-path: none  !important ;
  }
}
